//22 Jan 24 First Physio framework

/**
 * @license
 * Copyright 2021 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

import "@tensorflow/tfjs-backend-webgl";

import * as tfjsWasm from "@tensorflow/tfjs-backend-wasm";

import * as posedetection from "@tensorflow-models/pose-detection";

//import * as tf from '@tensorflow/tfjs';

import { Camera } from "./camera";

import { STATE } from "./params";

import configData from "./config.json";
import { time } from "@tensorflow/tfjs-core";
import { stat } from "browserify-fs";
import { useState } from "react";

tfjsWasm.setWasmPaths(
  `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${tfjsWasm.version_wasm}/dist/`
);

var stats = require("stats-lite");

let detector, net, dObj;

let camera = new Camera();

let rafId;
var arryLen;
var data,
  startTime = -1,
  timeLapsed = 0,
  b_timer,
  ls_log = "";

var ln_utterSpeed = 1,
  ln_promptTime = -11,
  ln_configRA = 15,
  n_modelType = 0,
  n_displayMode = -3,
  n_laJSY,
  n_raJSY,
  n_jsShoulderCheck = 0,
  n_exStartTime = 0,
  n_gameDuration = 120,
  n_score = 0,
  n_screenRefresh = 0.251,
  n_timeMonkeyHit = 20,
  b_SI = true,
  b_notSpeaking = true,
  b_learningStage = true,
  b_showSkeleton = true,
  b_restStage = false,
  b_showPlayer = false,
  s_comment = "",
  s_prevComment = "",
  n_lastCommentTime = -1,
  n_sceneID,
  n_predictID,
  ad_effect,
  ad_effectHit,
  ad_background,
  n_DKpIndex = -1,
  n_laJumpY,
  n_raJumpY;

var angleArray = [];
var oldMaxAngle = 0;

const h_partsIndex = new Map();

var synth = window.speechSynthesis;
var utterThis = new SpeechSynthesisUtterance();
var soundDuration = { jc: 0.5, jf: 1.5, running: 1 };
var soundEndTracking = { jc: -1, jf: -1, running: -1 };

function setComment(aTimeLapsed, aCommentStr, abOverwrite) {
  logIt(
    "s_prevComment: " +
      s_prevComment +
      " aCommentStr: " +
      aCommentStr +
      " n_lastCommentTime: " +
      n_lastCommentTime +
      " ln_configRA: " +
      ln_configRA
  );

  if (typeof aCommentStr === "undefined") {
    logIt("detected undefined str");
  } else if (
    aTimeLapsed - n_lastCommentTime > ln_configRA ||
    s_prevComment !== aCommentStr
  ) {
    logIt("Set comment: " + aCommentStr);

    if (s_comment.length === 0) s_comment = aCommentStr;
    else {
      if (abOverwrite) {
        s_comment = aCommentStr;
      }
    }
  }
}

function PlaySound(as_obj, a_sFileName, ab_loop) {
  // as_obj = new Audio(a_sFileName);
  as_obj.src = a_sFileName;
  as_obj.loop = ab_loop;
  as_obj.play();
}

function instructNow(instStr) {
  b_notSpeaking = true;
  ln_promptTime = timeLapsed - ln_configRA - 1;

  instruct(instStr);
}

function instruct(instStr) {
  instructBase(instStr, ln_configRA);
}

function instructBase(instStr, an_waitTime) {
  logIt("in instruct for: " + instStr);
  logIt(" with b_notSpeaking:" + b_notSpeaking);

  if (b_notSpeaking && timeLapsed - ln_promptTime > an_waitTime) {
    b_notSpeaking = false;

    if (b_SI) {
      utterThis.text = instStr;
      utterThis.rate = ln_utterSpeed;
      utterThis.volume = 1;
      utterThis.onend = function () {
        ln_promptTime = timeLapsed;
        b_notSpeaking = true;
        logIt(" now b_notSpeaking:" + b_notSpeaking);
      };
      ln_promptTime = timeLapsed;
      synth.speak(utterThis);
      logIt("spoke: " + instStr);
    }

    return true;
  } else return false;
}

/// End Display and Sound Functions

function logIt(als_log) {
  //var myTimeLapsed = (Date.now() - startTime)/1000;

  ls_log += timeLapsed + ": " + als_log + "\\n";
  //ls_log="";
}

/// Start Stats function

function generateRandomIntegerInRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getDistance(p1, p2, num) {
  if (num === 0) {
    return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
  } else {
    return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y + num - p2.y, 2));
  }
}

function toDegrees(angle) {
  return (angle * 180) / Math.PI;
}

function getANGby3Points(w, s, eb) {
  var AB, BC, AC;

  AB = getDistance(w, s, 100);
  BC = getDistance(s, eb, 0);
  AC = getDistance(w, eb, 100);

  //document.getElementById('angle').innerHTML = "AB2: " + (AB*AB) + " AC2: " + (AC*AC) + " BC2: " + (BC*BC);

  if (BC + AC + AB === 0 || AB === 0 || AC === 0) {
    return 370;
  } else {
    return toDegrees(
      // Math.acos((AB * AB + AC * AC - BC * BC) / (2 * AB * AC))
      Math.acos((BC * BC + AB * AB - AC * AC) / (2 * BC * AB))
    );

    //return (Dsw/(Dsw+Debw+Dseb)) * 180;
  }
}

function getSignedANGXY(pp, np) {
  if (pp.y - np.y === 0) return 90;
  else return (Math.atan((pp.x - np.x) / (pp.y - np.y)) * 180) / Math.PI;
}

function getArrayAvg(aarry, an_poseIndex, as_property) {
  var tmp_arry = [];

  for (var t = 0; t < aarry.length; t++) {
    if (as_property === "x") tmp_arry.push(aarry[t][an_poseIndex].x);
    else if (as_property === "x") tmp_arry.push(aarry[t][an_poseIndex].y);
    else tmp_arry.push(aarry[t][an_poseIndex].score);
  }

  //logIt("length arry: " + aarry.length  + " avg tmp_arryX:" +stats.mean(tmp_arryX) );

  return stats.mean(tmp_arry);
}

function getTrendY(aarry, anCheckLevel) {
  // asTrend: 'min' true when values get bigger; eg. diff +v;   for  can evolve into checkXTrend

  logIt("in checkYTrend with anCheckLevel:" + anCheckLevel);

  if (anCheckLevel + 1 < aarry.length && anCheckLevel > 0) {
    var n1stTrend =
      aarry[aarry.length - 1][n_DKpIndex].y -
      aarry[aarry.length - 2][n_DKpIndex].y;

    logIt("first Diff: " + n1stTrend);

    var nReturnValue = 0;

    if (n1stTrend > 0) nReturnValue = 1;
    else if (n1stTrend < 0) nReturnValue = -1;

    if (nReturnValue === 0) return 0;

    for (let i = 1; i < anCheckLevel; i++) {
      logIt(
        i +
          " Diff: " +
          (aarry[aarry.length - 1 - i][n_DKpIndex].y -
            aarry[aarry.length - 2 - i][n_DKpIndex].y)
      );

      if (nReturnValue > 0) {
        if (
          aarry[aarry.length - 1 - i][n_DKpIndex].y -
            aarry[aarry.length - 2 - i][n_DKpIndex].y <=
          0
        ) {
          logIt(i + " inconsistent in +ve trend return 0");
          return 0;
        }
      } else {
        // nReturnValue<0

        if (
          aarry[aarry.length - 1 - i][n_DKpIndex].y -
            aarry[aarry.length - 2 - i][n_DKpIndex].y >=
          0
        ) {
          logIt(i + " inconsistent in -ve trend return 0");
          return 0;
        }
      }
    }

    return nReturnValue;
  } else return -9;
}

function getArrayStdev(aarry, an_poseIndex, as_property) {
  var tmp_arry = [];

  for (var t = 0; t < aarry.length; t++) {
    if (as_property === "x") tmp_arry.push(aarry[t][an_poseIndex].x);
    else tmp_arry.push(aarry[t][an_poseIndex].y);
  }

  //logIt("length arry: " + aarry.length  + " avg tmp_arryX:" +stats.mean(tmp_arryX) );

  return stats.stdev(tmp_arry);
}

function getMonkeyAnimationLimit() {
  return Math.round((1 / n_screenRefresh) * n_timeMonkeyHit) + 1;
}

/// End Stats functions

/// Start Pose Logic functions

//import from Birdy a more powerful one
function getPL(p, an_scaleX, an_scaleY) {
  var ap_l = [];
  an_scaleX = 1;
  an_scaleX = 1;

  if (p[12].x > p[11].x) {
    p[0].x = p[0].x / an_scaleX;
    p[0].y = p[0].y / an_scaleY;
    ap_l.push(p[0]);
    for (var i = 1; i < 17; i++) {
      if (i % 2 === 0) {
        p[i - 1].x = p[i - 1].x / an_scaleX;
        p[i - 1].y = p[i - 1].y / an_scaleY;
        ap_l.push(p[i - 1]);
        //ap_l[i] = p[i-1];
      } else {
        p[i + 1].x = p[i + 1].x / an_scaleX;
        p[i + 1].y = p[i + 1].y / an_scaleY;
        ap_l.push(p[i + 1]);
        //ap_l[i] = p[i+1];
      }
    }
  } else {
    ap_l = p;
  }

  return ap_l;
}

function checkPosition(pl, anLowerLimit, anUpperLimit) {
  if (anLowerLimit < pl[15].y || anLowerLimit < pl[16].y) {
    // compare with ankles

    logIt(
      "n_lowerLimit: " +
        anLowerLimit +
        " left ankle:" +
        pl[15].y +
        " right ankle:" +
        pl[16].y
    );
    setComment(timeLapsed, "Take a step back", false);
  } else if (anUpperLimit > pl[1].y || anUpperLimit > pl[2].y) {
    // compare with eyes

    logIt(
      "n_upperLimit: " +
        anUpperLimit +
        " left eye:" +
        pl[1].y +
        " right eye:" +
        pl[2].y
    );
    setComment(timeLapsed, "Give more allowance to top", false);
  } else if (0.75 * camera.canvas.width < pl[9].x) {
    // check left wrist

    logIt(
      "0.8*camera.canvas.width: " +
        0.8 * camera.canvas.width +
        " pl[9].x:" +
        pl[9].x
    );
    setComment(timeLapsed, "Give more allowance to left", false);
  } else if (0.25 * camera.canvas.width > pl[10].x) {
    setComment(timeLapsed, "Give more allowance to right", false);
  } else {
    //n_stage = 1;
    logIt("all fine, returning true for position");
    return true;
  }

  return false;
}

function checkAnkleYStationary(aarryPoseSeq) {
  logIt(
    " getArrayStdev(aarryPoseSeq,16,y) " +
      getArrayStdev(aarryPoseSeq, 16, "y") +
      " getArrayStdev(aarryPoseSeq,15,y) " +
      getArrayStdev(aarryPoseSeq, 15, "y")
  );

  if (
    getArrayStdev(aarryPoseSeq, 16, "y") < 3 &&
    getArrayStdev(aarryPoseSeq, 15, "y") < 3
  )
    return true;
  else return false;
}

function checkAnkleStationary(aarryPoseSeq) {
  if (
    getArrayStdev(aarryPoseSeq, 16, "x") < 2 &&
    getArrayStdev(aarryPoseSeq, 16, "y") < 2 &&
    getArrayStdev(aarryPoseSeq, 15, "x") < 2 &&
    getArrayStdev(aarryPoseSeq, 15, "y") < 2
  )
    return true;
  else return false;
}

function checkPartsStability(aarryPoseSeq, aarryIndiceToCheck, anStdev) {
  logIt("in checkPartsStablility");

  for (let nIndex of aarryIndiceToCheck) {
    logIt(
      "index: " +
        nIndex +
        " y:" +
        getArrayStdev(aarryPoseSeq, nIndex, "y") +
        " x:" +
        getArrayStdev(aarryPoseSeq, nIndex, "x")
    );

    if (
      getArrayStdev(aarryPoseSeq, nIndex, "y") > anStdev ||
      getArrayStdev(aarryPoseSeq, nIndex, "x") > anStdev
    ) {
      logIt(
        "index: " +
          nIndex +
          " not stable. y: " +
          getArrayStdev(aarryPoseSeq, nIndex, "y") +
          " x: " +
          getArrayStdev(aarryPoseSeq, nIndex, "x")
      );
      return false;
    }
  }
  logIt("Parts are stable");

  return true;
}

function checkSplitShoulder(aCpl, anRightForward, anLeftForward, anRep) {
  var nlCalfY = aCpl[15].y - aCpl[13].y;
  var nrCalfY = aCpl[16].y - aCpl[14].y;

  var nCurrRatio = nlCalfY / nrCalfY;

  logIt(
    "checkBentKnees ratio: " +
      nCurrRatio +
      " anRightForward: " +
      anRightForward +
      " rsY: " +
      aCpl[6].y +
      " lsY: " +
      aCpl[5].y +
      " n_jsShoulderCheck: " +
      n_jsShoulderCheck +
      " anRep: " +
      anRep
  );

  if (anRep % 2 === 0) {
    // even Rep right knee forward

    logIt(
      "check condition nCurrRatio < anRightForward: " +
        (nCurrRatio < anRightForward)
    );
    if (nCurrRatio < anRightForward) {
      if (aCpl[6].y < aCpl[5].y)
        //
        return 1; // right knee bent forward
      else {
        n_jsShoulderCheck++;
        if (n_jsShoulderCheck > 2)
          setComment(timeLapsed, "Right shoulder forward");
      }
    } else if (nCurrRatio > anLeftForward) {
      return -1; // left knee bent forward
    }
  } else {
    // odd rep left knee forward

    logIt(
      "check condition nCurrRatio < anRightForward: " +
        (nCurrRatio < anRightForward)
    );
    if (nCurrRatio < anRightForward) {
      return -1; // right knee bent forward
    } else if (nCurrRatio > anLeftForward) {
      if (aCpl[6].y > aCpl[5].y) return 1; // left knee bent forward
      else {
        n_jsShoulderCheck++;
        if (n_jsShoulderCheck > 2)
          setComment(timeLapsed, "Left shoulder forward");
      }
    }
  }

  return 0;
}

function setRefLegY(aarryPoseSeq) {
  let nAvgRkY = getArrayAvg(aarryPoseSeq, 14, "y");
  let nAvgLkY = getArrayAvg(aarryPoseSeq, 13, "y");

  let nAvgRaY = getArrayAvg(aarryPoseSeq, 16, "y");
  let nAvgLaY = getArrayAvg(aarryPoseSeq, 15, "y");

  n_laJumpY = nAvgLaY - 0.4 * (nAvgLaY - nAvgLkY);
  n_raJumpY = nAvgRaY - 0.4 * (nAvgRaY - nAvgRkY);

  n_laJSY = nAvgLaY - 0.2 * (nAvgLaY - nAvgLkY);
  n_raJSY = nAvgRaY - 0.2 * (nAvgRaY - nAvgRkY);

  logIt(
    "in setRefLegY set nAvgRkY: " +
      nAvgRkY +
      " nAvgLkY: " +
      nAvgLkY +
      " nAvgRaY: " +
      nAvgRaY +
      " nAvgLaY:" +
      nAvgLaY +
      " n_laJSY: " +
      n_laJSY +
      " n_raJSY: " +
      n_raJSY
  );
}

/// End Pose Logic

/// Start video capturing/ AI

async function createDetector() {
  STATE.model = posedetection.SupportedModels.MoveNet;
  let modelType;
  if (n_modelType === 0) {
    modelType = posedetection.movenet.modelType.SINGLEPOSE_LIGHTNING;
  } else {
    modelType = posedetection.movenet.modelType.SINGLEPOSE_THUNDER;
  }

  const modelConfig = { modelType };
  modelConfig.enableTracking = STATE.modelConfig.enableTracking;

  return posedetection.createDetector(STATE.model, modelConfig);
}

function sendData(aFileName) {
  b_timer = false;

  const axios = require("axios");

  var currentdate = new Date();
  var newFileName =
    aFileName +
    "_" +
    currentdate.getDate() +
    "_" +
    (currentdate.getMonth() + 1) +
    "_" +
    "t" +
    currentdate.getHours() +
    "_" +
    currentdate.getMinutes() +
    "_" +
    currentdate.getSeconds();
  var bodyFormData = new FormData();

  bodyFormData.append("afileName", newFileName);
  bodyFormData.append("csvData", data);
  bodyFormData.append("csvLog", ls_log);

  var s_url = "";

  s_url = "https://csm2.achieversprofile.com/c/d?handler=Log";

  axios({
    method: "post",
    url: s_url,

    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {
      //handle success
      //console.log(response); w:480, H:640
    })
    .catch(function (response) {
      //handle error
      //saveStatus.innerHTML = response;
      //document.getElementById('save').style.visibility = "hidden";
      //console.log(response);
    });
}

async function renderResult() {
  timeLapsed = (Date.now() - startTime) / 1000;

  logIt(
    "Timelapsed: " +
      timeLapsed +
      " n_exStartTime: " +
      n_exStartTime +
      " condition is " +
      (timeLapsed - n_exStartTime < n_gameDuration)
  );

  //  if ( n_exStartTime===0 || (timeLapsed-n_exStartTime)<n_gameDuration){

  if (camera !== null) {
    let poses = null;

    // Detector can be null if initialization failed (for example when loading
    // from a URL that does not exist).
    if (detector != null) {
      // FPS only counts the time it takes to finish estimatePoses.

      // Detectors can throw errors, for example when using custom URLs that
      // contain a model that doesn't provide the expected output

      try {
        poses = await detector.estimatePoses(camera.video, {
          maxPoses: STATE.modelConfig.maxPoses,
          flipHorizontal: false,
        });
      } catch (error) {
        detector.dispose();
        detector = null;
        alert(error);
      }
    }

    document.getElementById("loading").style.display = "none";

    // console.log("poses =====>", poses);

    // The null check makes sure the UI is not in the middle of changing to a
    // different model. If during model change, the result is from an old model,
    // which shouldn't be rendered.
    if (poses && poses.length > 0 && timeLapsed > 5) {
      //camera.drawResults(poses);

      document.getElementById("duration").innerText = timeLapsed;

      data = data + "\\n";

      var p_l = getPL(poses[0].keypoints);
      poses[0].keypoints = p_l;
      var p = poses[0].keypoints;

      data =
        data +
        timeLapsed +
        "," +
        p[0].x +
        "," +
        p[0].y +
        "," +
        p[0].score +
        "," +
        p[1].x +
        "," +
        p[1].y +
        "," +
        p[1].score +
        "," +
        p[2].x +
        "," +
        p[2].y +
        "," +
        p[2].score +
        "," +
        p[3].x +
        "," +
        p[3].y +
        "," +
        p[3].score +
        "," +
        p[4].x +
        "," +
        p[4].y +
        "," +
        p[4].score +
        "," +
        p[5].x +
        "," +
        p[5].y +
        "," +
        p[5].score +
        "," +
        p[6].x +
        "," +
        p[6].y +
        "," +
        p[6].score +
        "," +
        p[7].x +
        "," +
        p[7].y +
        "," +
        p[7].score +
        "," +
        p[8].x +
        "," +
        p[8].y +
        "," +
        p[8].score +
        "," +
        p[9].x +
        "," +
        p[9].y +
        "," +
        p[9].score +
        "," +
        p[10].x +
        "," +
        p[10].y +
        "," +
        p[10].score +
        "," +
        p[11].x +
        "," +
        p[11].y +
        "," +
        p[11].score +
        "," +
        p[12].x +
        "," +
        p[12].y +
        "," +
        p[12].score +
        "," +
        p[13].x +
        "," +
        p[13].y +
        "," +
        p[13].score +
        "," +
        p[14].x +
        "," +
        p[14].y +
        "," +
        p[14].score +
        "," +
        p[15].x +
        "," +
        p[15].y +
        "," +
        p[15].score +
        "," +
        p[16].x +
        "," +
        p[16].y +
        "," +
        p[16].score +
        ",";

      data = data + timeLapsed;
      //b_showPlayer=true;
      camera.drawCtx();
      if (b_showSkeleton) camera.drawResults(poses);

      var p1 = poses[0].keypoints[6];
      var p2 = poses[0].keypoints[6];
      var p3 = poses[0].keypoints[8];

      var angle = 0;

      // p1 is shoulder, p3 is elbow
      if (p1.x < p3.x) {
        angle = getANGby3Points(p1, p2, p3);
      } else if (p1.x >= p3.x) {
        var newAngle = getANGby3Points(p1, p2, p3);
        angle = 360 - newAngle;
      }
      
      if (angle <= 270){
        angleArray.push(angle);
      }
      // eslint-disable-next-line use-isnan
      var filterArray = angleArray.filter(v => v !== NaN);

      // eslint-disable-next-line use-isnan
      // console.log('isNaN ====>', angleArray.filter(v => v === NaN).length > 0)
      var max = filterArray.reduce((a, b) => Math.max(a, b), -Infinity);
      
      // console.log("angle ========>", angle);
      document.getElementById("angle").innerText = angle.toFixed(0);
      document.getElementById("maxAngle").innerText = max.toFixed(0);

      // console.log("angleArray =====>", angleArray);

      // if (angleArray.length == 10) {
      // const max = angleArray.reduce((a, b) => Math.max(a, b), -Infinity);
      // if (oldMaxAngle < max) {
      //   oldMaxAngle = max;
      // }

      // console.log('angle Max =======>', max);
      // }

      logIt(
        "display mode: " +
          n_displayMode +
          " learningStage: " +
          b_learningStage +
          " showPlayer :" +
          b_showPlayer
      );
      //if ((n_displayMode<4 && b_learningStage)||b_showPlayer){
    }
  }

  //sayComment(timeLapsed);
} // RenderResult

async function setUpCapturing() {
  //await tf.setBackend(STATE.backend);

  try {
    camera = await Camera.setupCamera(STATE.camera);
  } catch (err) {
    alert(err);
  }

  detector = await createDetector();

  /*
    o_selectionObj= new ObjSelection(camera.canvas.width,["Training Run & Tap","Training Jump & Smash","Training Jump Switch & Jump Smash","Survivor","End"]
    ,["F_3min.png","F_5min.png","F_unlimited.png","F_3min.png","F_5min.png"],[1,2,3,9,-1]);
    */

  n_predictID = setInterval(renderResult, 200);

  //PlaySound(ad_background,"Background.mp3",true);
  //renderPrediction();
}

async function renderPrediction() {
  if (b_timer) {
    try {
      await renderResult();
      rafId = requestAnimationFrame(renderPrediction);
    } catch (err1) {
      logIt("Error under renderPrediction:" + err1);
      //instruct("Err in renderPrediction");
    }
  }
}
///End External interaction

async function app() {
  b_timer = true;

  // n_lastResponseTime = 20;

  h_partsIndex.set("shoulder", 5);
  h_partsIndex.set("nose", 0);
  h_partsIndex.set("hip", 11);
  h_partsIndex.set("ankle", 15);
  h_partsIndex.set("wrist", 9);
  h_partsIndex.set("elbow", 7);

  // var tArry = [{y:1},{y:3},{y:3},{y:2},{y:1}];

  //var nt = getTrendY(tArry,3);

  //logIt("return trend: " + nt);

  //let test = Math.round((1/n_screenRefresh)*n_timeMonkeyHit) + 1;

  // testing animation

  /*
 
arryChoiceSeq = [[{prompt:"Run",sID:1},{prompt:"Tap",sID:6}]
                ,[{prompt:"Jump",sID:2},{prompt:"Smash",sID:4},]
                ,[{prompt:"Jump Switch",sID:3},{prompt:"Jump Smash",sID:5}]
                ,[{prompt:"Run",sID:1},{prompt:"Jump",sID:2},{prompt:"Jump Switch",sID:3},{prompt:"Smash",sID:4},{prompt:"Tap",sID:6},{prompt:"Jump Smash",sID:5}]
                ];

                */
  startTime = Date.now();

  //redirectAction();

  document.getElementById("send").addEventListener("click", function () {
    clearTimeout(n_sceneID);
    clearTimeout(n_predictID);
    sendData(document.getElementById("logName").value);
  });

  /*
document.getElementById('extract').addEventListener("click", function(){

  document.getElementById('videoFrame').src = camera.canvas.toDataURL();

});
*/
  document.getElementById("start").addEventListener("click", function () {
    document.getElementById("loading").style.display = "block";
    document.getElementById("start").style.display = "none";

    //instructNow("Starting Game!");

    var s_inAudible =
      "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

    ad_background = new Audio();
    ad_background.src = s_inAudible;
    ad_background.volume = 1.0;
    ad_background.play();

    ad_effect = new Audio();
    ad_effect.src = s_inAudible;
    ad_effect.volume = 1.0;
    ad_effect.play();

    ad_effectHit = new Audio();
    ad_effectHit.src = s_inAudible;
    ad_effectHit.volume = 1.0;
    ad_effectHit.play();

    setUpCapturing();
  });

  document.getElementById("send").style.fontSize = "15px";
  document.getElementById("statusBar").style.fontSize = "15px";

  data =
    "DT,nX,nY,nScore" +
    ",leX,leY,leScore,reX,reY,reScore,learX,learY,learScore,rearX,rearY,rearScore" +
    ",lsX,lsY,lsScore,rsX,rsY,rsScore,lebX,lebY,lebScore,rebX,rebY,rebScore" +
    ",lwX,lwY,lwScore,rwX,rwY,rwScore" +
    ",lhX,lhY,lhScore,rhX,rhY,rhScore,lkX,lkY,lkScore,rkX,rkY,rkScore" +
    ",laX,laY,laScore,raX,raY,raScore,DT";
}

document.getElementById("stop").addEventListener("click", function () {
  camera.stopVideo();
});

app();
