/**
 * @license
 * Copyright 2021 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */
import * as posedetection from "@tensorflow-models/pose-detection";

import * as params from "./params";
import { isMobile } from "./util";

const COLOR_PALETTE = [
  "#ffffff",
  "#800000",
  "#469990",
  "#e6194b",
  "#42d4f4",
  "#fabed4",
  "#aaffc3",
  "#9a6324",
  "#000075",
  "#f58231",
  "#4363d8",
  "#ffd8b1",
  "#dcbeff",
  "#808000",
  "#ffe119",
  "#911eb4",
  "#bfef45",
  "#f032e6",
  "#3cb44b",
  "#a9a9a9",
];

export class Camera {
  constructor() {
    this.video = document.getElementById("video");
    this.canvas = document.getElementById("output");
    this.ctx = this.canvas.getContext("2d");
    this.scoreThreshold = 0.2;
  }

  /**
   * Initiate a Camera instance and wait for the camera stream to be ready.
   * @param cameraParam From app `STATE.camera`.
   */
  static async setupCamera(cameraParam) {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      throw new Error("This site requires a functional camera.");
    }

    const { targetFPS, sizeOption } = cameraParam;
    const $size = params.VIDEO_SIZE[sizeOption];
    const videoConfig = {
      audio: false,
      video: {
        facingMode: "user",
        // Only setting the video to a specified size for large screen, on
        // mobile devices accept the default size.
        width: isMobile() ? params.VIDEO_SIZE["640 X 480"].width : $size.width,
        height: isMobile()
          ? params.VIDEO_SIZE["640 X 480"].height
          : $size.height,
        frameRate: {
          ideal: targetFPS,
        },
      },
    };

    const stream = await navigator.mediaDevices.getUserMedia(videoConfig);

    const camera = new Camera();
    camera.video.srcObject = stream;

    await new Promise((resolve) => {
      camera.video.onloadedmetadata = (video) => {
        resolve(video);
        //resolve();
      };
    });

    camera.video.play();

    const videoWidth = camera.video.videoWidth;
    const videoHeight = camera.video.videoHeight;
    // Must set below two lines, otherwise video element doesn't show.
    var sFactor = 1,
      vsFactor = 1;

    if (window.screen.height > window.screen.width) {
      // portrait

      camera.video.width = videoWidth;
      camera.video.height = videoHeight;

      camera.canvas.width = videoWidth;
      camera.canvas.height = videoHeight;

      if (window.screen.width < videoWidth) {
        sFactor = window.screen.width / videoWidth;
      }
    } else {
      if (window.screen.height >= 720 && window.screen.width >= 960) {
        camera.video.width = videoWidth;
        camera.video.height = videoHeight;

        camera.canvas.width = videoWidth;
        camera.canvas.height = videoHeight;
      } else if (
        window.screen.height >= videoHeight &&
        window.screen.width >= videoWidth
      ) {
        camera.video.width = videoWidth;
        camera.video.height = videoHeight;

        camera.canvas.width = videoWidth;
        camera.canvas.height = videoHeight;
      } else {
        camera.video.width = videoWidth;
        camera.video.height = videoHeight;

        camera.canvas.width = videoWidth;
        camera.canvas.height = videoHeight;

        if (window.screen.width < videoWidth) {
          sFactor = window.screen.width / videoWidth;
        }
      }
    }

    //  const canvasContainer = document.querySelector('.canvas-wrapper');
    //  canvasContainer.style = `width: ${camera.video.width}px; height: ${camera.video.height}px`;

    //canvasContainer.style = `width: 100%; height: 100%`; output

    // Because the image from camera is mirrored, need to flip horizontally.
    camera.ctx.translate(camera.video.videoWidth * sFactor, 0);
    //camera.ctx.translate(camera.video.width, 0);
    camera.ctx.scale(-1 * sFactor, sFactor);

    return camera;
  }

  drawCtx() {
    this.ctx.drawImage(
      this.video,
      0,
      0,
      this.video.videoWidth,
      this.video.videoHeight
    );
  }

  clearCtx() {
    this.ctx.clearRect(0, 0, this.video.videoWidth, this.video.videoHeight);
  }

  stopVideo() {
    const stream = this.video.srcObject;
    const tracks = stream.getTracks();

    tracks.forEach(function (track) {
      track.stop();
    });

    //tracks[0].stop();
    this.video.srcObject = null;
  }
  videoStop() {
    this.video.pause();
    this.video.srcObject = "";
  }

  drawRect(boxVal) {
    const [x, y, width, height] = boxVal["bbox"];
    const color = Math.floor(Math.random() * 16777215).toString(16);
    this.ctx.strokeStyle = "#" + color;

    // Draw rectangles and text
    this.ctx.beginPath();
    this.ctx.fillStyle = "#" + color;

    this.ctx.rect(x, y, width, height);
    this.ctx.stroke();
  }

  /**
   * Draw the keypoints and skeleton on the video.
   * @param poses A list of poses to render.
   */
  drawResults(poses) {
    for (const pose of poses) {
      this.drawResult(pose);
    }
  }

  /**
   * Draw the keypoints and skeleton on the video.
   * @param pose A pose with keypoints to render.
   */
  drawResult(pose) {
    if (pose.keypoints != null) {
      var bCenterView = false;
      if (
        pose.keypoints[0].x < pose.keypoints[5].x &&
        pose.keypoints[0].x > pose.keypoints[6].x
      ) {
        bCenterView = true;
      }

      //this.drawKeypoints(pose.keypoints,bCenterView);

      this.drawKeypoint(pose.keypoints[6]);
      this.drawKeypoint(pose.keypoints[8]);
      // this.drawKeypoint(pose.keypoints[6]);
      this.drawAngleCircle(pose.keypoints);

      this.drawSkeletonSide(pose.keypoints, pose.id);

      /*

      if (bCenterView){
        this.drawSkeleton(pose.keypoints, pose.id);
      }else{
        this.drawSkeletonSide(pose.keypoints, pose.id);
      }

      */
    }
  }

  /**
   * Draw the keypoints on the video.
   * @param keypoints A list of keypoints.
   */
  drawKeypoints(keypoints, abCenterView) {
    const keypointInd = posedetection.util.getKeypointIndexBySide(
      params.STATE.model
    );
    this.ctx.fillStyle = "White";
    this.ctx.strokeStyle = "White";
    this.ctx.lineWidth = params.DEFAULT_LINE_WIDTH;

    for (const i of keypointInd.middle) {
      this.drawKeypoint(keypoints[i]);
    }

    if (abCenterView) {
      this.ctx.fillStyle = "Green";
      for (const i of keypointInd.left) {
        this.drawKeypoint(keypoints[i]);
      }
    }

    this.ctx.fillStyle = "Orange";
    for (const i of keypointInd.right) {
      this.drawKeypoint(keypoints[i]);
    }
  }

  drawKeypoint(keypoint) {
    // If score is null, just show the keypoint.
    const score = keypoint.score != null ? keypoint.score : 1;
    //const scoreThreshold = params.STATE.modelConfig.scoreThreshold || 0;
    this.ctx.fillStyle = "Orange";
    //  if (score >= this.scoreThreshold) {
    const circle = new Path2D();
    circle.arc(keypoint.x, keypoint.y, params.DEFAULT_RADIUS, 0, 2 * Math.PI);
    this.ctx.fill(circle);
    this.ctx.stroke(circle);
    //  }
  }

  drawAngleCircle(keypoint) {
    // var dx1 = pt1.x - pt2.x;
    // var dy1 = pt1.y - pt2.y;
    // var dx2 = pt3.x - pt2.x;
    // var dy2 = pt3.y - pt2.y;
    // var a1 = Math.atan2(dy1, dx1);
    // var a2 = Math.atan2(dy2, dx2);
    var xShoulder = keypoint[6].x;
    var yShoulder = keypoint[6].y;
    var xElbow = keypoint[8].x;
    var yElbow = keypoint[8].y;
    var xVertical = keypoint[6].x;
    var yVertical = keypoint[6].y + 100;

    var dx1 = xElbow - xShoulder;
    var dy1 = yElbow - yShoulder;
    var dx2 = xVertical - xShoulder;
    var dy2 = yVertical - yShoulder;
    var a1 = Math.atan2(dy1, dx1);
    var a2 = Math.atan2(dy2, dx2);

    this.ctx.save();
    this.ctx.beginPath();
    this.ctx.moveTo(xShoulder, yShoulder);
    this.ctx.arc(xShoulder, yShoulder, 30, a1, a2);
    this.ctx.closePath();
    this.ctx.fillStyle = "red";
    this.ctx.globalAlpha = 0.25;
    this.ctx.fill();
    this.ctx.restore();

    // var a = parseInt(((a2 - a1) * 180) / Math.PI + 360) % 360;
    // this.ctx.fillStyle = "black";
    // this.ctx.fillText(a, xShoulder + 15, yShoulder);
    // var startPointx = keypoint[6].x;
    // var startPointy = Math.PI;
    // var startPoint = Math.sqrt(startPointx ** 2 + startPointy ** 2);
    // console.log('x and y ====>', startPointy)

    // let endPointx = 0;
    // let endPointy = 0;

    // endPointx = keypoint[6].x + (keypoint[8].x - keypoint[6].x) * 0.2;
    // endPointy = keypoint[6].y + (keypoint[8].y - keypoint[6].y) * 0.2;

    // var endPoint = Math.sqrt(endPointx ** 2 + endPointy ** 2);

    // endPointx /= 2;
    // endPointy /= 2;
    // console.log('x and y ====>', endPointx, endPointy);

    // this.ctx.fillStyle = "red";
    //  if (score >= this.scoreThreshold) {
    // const circle = new Path2D();
    // circle.arc(keypoint.x, keypoint.y, params.DEFAULT_RADIUS, 0, 2 * Math.PI);
    // this.ctx.fill(circle);
    // this.ctx.stroke(circle);
  }

  /**
   * Draw the skeleton of a body on the video.
   * @param keypoints A list of keypoints.
   */
  drawSkeleton(keypoints, poseId) {
    /*

    const color = params.STATE.modelConfig.enableTracking && poseId != null ?
        COLOR_PALETTE[poseId % 20] :
        'White';

*/
    const color = poseId != null ? COLOR_PALETTE[poseId % 20] : "White";

    this.ctx.fillStyle = color;
    this.ctx.strokeStyle = color;
    this.ctx.lineWidth = params.DEFAULT_LINE_WIDTH;

    posedetection.util
      .getAdjacentPairs(params.STATE.model)
      .forEach(([i, j]) => {
        const kp1 = keypoints[i];
        const kp2 = keypoints[j];

        // If score is null, just show the keypoint.
        const score1 = kp1.score != null ? kp1.score : 1;
        const score2 = kp2.score != null ? kp2.score : 1;
        //const scoreThreshold = params.STATE.modelConfig.scoreThreshold || 0;

        //      if (score1 >= this.scoreThreshold && score2 >= this.scoreThreshold) {
        this.ctx.beginPath();
        this.ctx.moveTo(kp1.x, kp1.y);
        this.ctx.lineTo(kp2.x, kp2.y);
        this.ctx.stroke();
        //      }
      });
  }

  drawSkeletonSide(keypoints, poseId) {
    var color = "Blue";

    this.ctx.fillStyle = color;
    this.ctx.strokeStyle = color;
    // Vertical line
    this.ctx.beginPath();
    this.ctx.moveTo(keypoints[6].x, keypoints[6].y);
    this.ctx.lineTo(keypoints[6].x, keypoints[6].y + 100);
    this.ctx.stroke();

    color = "Green";

    this.ctx.fillStyle = color;
    this.ctx.strokeStyle = color;
    // Vertical line
    this.ctx.beginPath();
    this.ctx.moveTo(keypoints[6].x, keypoints[6].y);
    this.ctx.lineTo(keypoints[8].x, keypoints[8].y);
    this.ctx.stroke();

    // color = 'red';

    // this.ctx.fillStyle = color;
    // this.ctx.strokeStyle = color;
    // // Vertical line
    // this.ctx.beginPath();
    // this.ctx.moveTo(keypoints[6].x, keypoints[6].y+100);
    // this.ctx.lineTo(keypoints[8].x, keypoints[8].y);
    // this.ctx.stroke();
  }

  drawPic(imgEle, ptXY, width, height) {
    this.ctx.drawImage(imgEle, ptXY.x, ptXY.y, width, height);
  }

  drawRotateDropPic(imgEle, ptXY, width, height, angDegress) {
    /*

    var x = this.canvas.width/2;
    var y = this.canvas.height/2;

    */

    let x = ptXY.x;
    let y = ptXY.y;

    var TO_RADIANS = Math.PI / 180;
    var angleInRadians = angDegress * TO_RADIANS;

    if (angDegress === 225) y += 0.5 * height;
    else if (angDegress === 270) y += height;
    else if (angDegress === 315) y += 1.5 * height;

    this.ctx.translate(x, y);
    this.ctx.rotate(angleInRadians);
    this.ctx.drawImage(imgEle, -width / 2, -height / 2, width, height);
    // this.ctx.drawImage(imgEle, -width/2, -height/2, width, height);
    //this.ctx.drawImage(imgEle, (-width/2), (-height / 2)-ptXY.y , width, height);
    //this.ctx.drawImage(imgEle, -width / 2, -ptXY.y , width, height);

    this.ctx.rotate(-angleInRadians);
    this.ctx.translate(-x, -y);
  }

  drawTarget(x, y, size) {
    this.ctx.fillStyle = "Red";
    this.ctx.strokeStyle = "White";
    this.ctx.lineWidth = params.DEFAULT_LINE_WIDTH;

    const circle = new Path2D();
    circle.arc(x, y, size, 0, 2 * Math.PI);
    this.ctx.fill(circle);
    this.ctx.stroke(circle);
  }
}
